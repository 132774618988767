import React from "react"

import Card from "../modules/Card"

export default ({ days }) => (
  <div
    // className="uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-2@m uk-child-width-1-3@l uk-child-width-1-4@xl uk-text-center uk-flex-center"
    className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-1@m uk-child-width-1-2@l uk-child-width-1-3@xl uk-text-center uk-flex-center"
    data-uk-grid
  >
    {days &&
      days.map(day => {
        return (
          <div key={day.contentful_id}>
            <Card
              link={
                "/" +
                day.part[0].event[0].slug +
                "/" +
                day.part[0].slug +
                "/" +
                day.slug
              }
              fluid={day.cover.fluid}
              title={day.title}
              label={day.name}
            />
          </div>
        )
      })}
  </div>
)
