import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"
import textversionjs from "textversionjs"

import Context from "../components/Context"
import Comments from "../components/Comments"
import SEO from "../components/Seo"
import Cover from "../components/Cover"
import Description from "../components/Description"
import Days from "../components/Days"
import Photos from "../components/Photos"

import Follow from "../components/Follow"
import Pagination from "../modules/Pagination"
import Anchor from "../modules/Anchor"
import ScrollDown from "../modules/ScrollDown"
import ScrollUp from "../modules/ScrollUp"

import { slugify } from "../functions/slugify"
import { getContentfulEntry } from "../functions/apiLambda"
import { filterCommentsByEntry } from "../functions/contentfulData"
import moment from "../functions/moment"

export default class Part extends Component {
  state = {
    sections: {
      cover: "couverture",
      description: "résumé",
      days: "jours",
      photos: "photos",
      comments: "commentaires",
    },
  }

  componentDidMount() {
    if (this.context.data.sections !== this.state.sections) {
      this.context.set({ sections: this.state.sections })
    }

    const { data } = this.props

    // current page
    const part = data.part
    const event = part.event[0]

    let allPhotos = []
    part.days &&
      part.days.forEach(day => {
        day.photos &&
          day.photos.forEach(photo => {
            allPhotos = [
              ...allPhotos,
              { caption: day.name + " : " + day.title, ...photo },
            ]
          })
      })
    if (this.state.allPhotos !== allPhotos) {
      this.setState({ allPhotos })
    }

    const header = {
      currentName: part.name,
      currentType: "part",
      eventName: event.name,
      eventLink: "/" + event.slug,
      buttons: this.state.sections,
      counts: {
        days: part.days ? part.days.length : null,
        photos: allPhotos ? allPhotos.length : null,
        comments: this.state.comments ? this.state.comments.length : null,
      },
    }
    if (this.context.data.header !== header) {
      this.context.set({ header })
    }

    const { mapdata } = part
    if (this.context.data.mapdata !== mapdata) {
      this.context.set({ mapdata })
    }
  }

  componentDidUpdate() {
    const { allComments } = this.context.data

    // console.log(
    //   "PART ==> componentDidUpdate() ==> ",
    //   allComments,
    //   this.state.allComments
    // )

    if (allComments) {
      if (this.state.allComments !== allComments) {
        this.setState({ allComments })

        getContentfulEntry(this.props.data.part.contentful_id)
          // Filter current entry comments
          .then(entry => {
            return filterCommentsByEntry(allComments, entry)
          })
          // Add comments to state
          .then(matchComments => {
            this.setState({ comments: matchComments })
            const commentsCount = matchComments.length
            if (this.context.data.header.counts.comments !== commentsCount) {
              let header = { ...this.context.data.header }
              header.counts.comments = commentsCount
              this.context.set({ header })
            }
          })
      }
    }
  }

  render() {
    const { sections, allPhotos } = this.state
    const { data } = this.props

    // current page
    const part = data.part
    const event = part.event[0]
    // previous page
    const prevPart = data.prevPart
    const prevPartEvent = prevPart && prevPart.event[0]
    const prevUrl = prevPart && "/" + prevPartEvent.slug + "/" + prevPart.slug
    // next page
    const nextPart = data.nextPart
    const nextPartEvent = nextPart && nextPart.event[0]
    const nextUrl = nextPart && "/" + nextPartEvent.slug + "/" + nextPart.slug

    return (
      <Fragment>
        {/* SEO */}

        {part && (
          <SEO
            pageTitle={event && `${event.title} ∙ ${part.name} : ${part.title}`}
            pageDescription={
              (part.description &&
                textversionjs(part.description.childMarkdownRemark.html)) ||
              (event.description &&
                textversionjs(event.description.childMarkdownRemark.html))
            }
            pageImage={part.thumb && part.thumb.fluid.src}
            pageUrl={
              this.context.data.metadata.siteUrl +
              "/" +
              event.slug +
              "/" +
              part.slug
            }
          />
        )}

        {/* END : SEO */}

        {/* COVER */}

        <Anchor id={sections.cover} />

        <Cover
          current={part}
          prev={prevUrl}
          next={nextUrl}
          scroll={sections.description}
        />

        <Anchor id={"parallax-menu"} />

        {/* END : COVER */}

        {/* DESCRIPTION */}

        <Anchor id={sections.description} />

        <div className="uk-background-default">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.cover)} />
            </div>

            <div className="uk-container uk-container-medium">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.description}
              </h3>

              <Description
                date={
                  "du " +
                  moment(part.days[0].date).format("D MMMM YYYY") +
                  " au " +
                  moment(part.days[part.days.length - 1].date).format(
                    "D MMMM YYYY"
                  )
                }
                text={
                  part.description && part.description.childMarkdownRemark.html
                }
                youtube={part.youtube}
                thumb={part.thumb}
              />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={
                  prevUrl && prevUrl + "#" + slugify(sections.description)
                }
                prevTitle={prevPart && prevPart.name + " : " + prevPart.title}
                nextSlug={
                  nextUrl && nextUrl + "#" + slugify(sections.description)
                }
                nextTitle={nextPart && nextPart.name + " : " + nextPart.title}
              />
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.days)} />
            </div>
          </div>
        </div>

        {/* END : DESCRIPTION */}

        {/* DAYS */}

        <Anchor id={sections.days} />

        <div className="uk-background-muted">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.description)} />
            </div>

            <div className="uk-container uk-container-medium">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.days}
              </h3>

              <Days days={part.days} />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={prevUrl && prevUrl + "#" + slugify(sections.days)}
                prevTitle={prevPart && prevPart.name + " : " + prevPart.title}
                nextSlug={nextUrl && nextUrl + "#" + slugify(sections.days)}
                nextTitle={nextPart && nextPart.name + " : " + nextPart.title}
              />
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.photos)} />
            </div>
          </div>
        </div>

        {/* END : DAYS */}

        {/* PHOTOS */}

        <Anchor id={sections.photos} />

        <div className="uk-background-secondary uk-light">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.days)} />
            </div>

            <div className="uk-container uk-container-medium">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.photos}
              </h3>

              <Photos photos={allPhotos} />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={prevUrl && prevUrl + "#" + slugify(sections.photos)}
                prevTitle={prevPart && prevPart.name + " : " + prevPart.title}
                nextSlug={nextUrl && nextUrl + "#" + slugify(sections.photos)}
                nextTitle={nextPart && nextPart.name + " : " + nextPart.title}
              />
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.comments)} />
            </div>
          </div>
        </div>

        {/* END : PHOTOS */}

        {/* COMMENTS */}

        <Anchor id={sections.comments} />

        {/* <div className="uk-overlay uk-overlay-primary uk-light uk-padding-remove"> */}
        <div className="uk-background-muted">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.photos)} />
            </div>

            <div className="uk-container uk-container-medium uk-animation-fade">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.comments}
              </h3>

              <Comments
                comments={this.state.comments}
                reference={part.contentful_id}
                // url={window.location.origin + window.location.pathname}
              />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={prevUrl && prevUrl + "#" + slugify(sections.comments)}
                prevTitle={prevPart && prevPart.name + " : " + prevPart.title}
                nextSlug={nextUrl && nextUrl + "#" + slugify(sections.comments)}
                nextTitle={nextPart && nextPart.name + " : " + nextPart.title}
              />
            </div>
          </div>
        </div>

        {/* END : COMMENTS */}
      </Fragment>
    )
  }
}

Part.contextType = Context

export const query = graphql`
  query($partId: String!, $prevPartId: String!, $nextPartId: String!) {
    part: contentfulPart(contentful_id: { eq: $partId }) {
      contentful_id
      name
      title
      subtitle
      slug
      cover {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      youtube
      thumb {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      mapdata
      event {
        contentful_id
        name
        title
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
        blog {
          contentful_id
        }
      }
      days {
        contentful_id
        name
        title
        slug
        date
        cover {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid
          }
        }
        photos {
          contentful_id
          title
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid
          }
        }
        trace {
          content
        }
        part {
          slug
          event {
            slug
          }
        }
      }
    }
    prevPart: contentfulPart(contentful_id: { eq: $prevPartId }) {
      name
      title
      slug
      event {
        slug
      }
    }
    nextPart: contentfulPart(contentful_id: { eq: $nextPartId }) {
      name
      title
      slug
      event {
        slug
      }
    }
  }
`
